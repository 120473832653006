import RenderBlogContentComponent from "./components/BlogContent";
import RenderBlogsComponent from "./components/Blogs";

const ComponentMap = {
	blogs: {
		element: RenderBlogsComponent,
		context: ["blogs"],
	},
	blogContent: {
		element: RenderBlogContentComponent,
		context: ["blogs"],
	},
};

export default ComponentMap;
