"use client";
import React, { useEffect, useRef } from "react";
import IngredientsAndInstructions from "./partials/IngredientsAndInstructions";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useScrollResize, useContentScroll } from "./partials/scroll";
import { formatMarkdown } from "./partials/utils";
import { redirect } from "next/navigation";
import { minutesPrep } from "./partials/text";
import { StickyBanner } from "./partials/StickyBanner";
import Cta from "./partials/Cta";
import { APP_ENV } from "@/shared/config";
import { slugify } from "@/utils/string";

type BlogsProps = {
	data: any;
	mobileView: boolean;
	context?: any;
	pageArgs?: any;
};

const RenderRecipe = ({ data, pageArgs, mobileView }: BlogsProps) => {
	const contentRef = useRef(null);
	const containerRef = useRef(null);
	useContentScroll(contentRef, containerRef);

	const recipe = data;

	const RecipeImage = () => {
		//TODO fix image url
		const environment = APP_ENV?.includes("dev") ? "dev" : "prod";
		const imageUrl = recipe.images[0];

		const initialHeight = 500;
		const minHeight = 416;
		const imageHeight = useScrollResize(initialHeight, minHeight);
		const imageStyle = mobileView
			? {
					backgroundImage: `url("${imageUrl}")`,
					height: `500px`,
					backgroundSize: `auto ${imageHeight}px`,
			  }
			: {
					backgroundImage: `url("${imageUrl}")`,
			  };
		return (
			<div className={"md:w-[55%]"}>
				<div
					className={`inset-0 w-full h-full bg-cover
				    ${mobileView && window.scrollY > 88 ? "bg-bottom" : "bg-center"}`}
					style={imageStyle}
				></div>
			</div>
		);
	};

	const RecipeTime = () => {
		return (
			<div className={"flex gap-1 text-green1 align-middle mb-2"}>
				<AccessTimeIcon
					className={"my-auto"}
					style={{
						fontSize: 20,
					}}
				/>
				<span>
					<small>
						{`${recipe.preparation_time_in_minutes} ${
							minutesPrep[pageArgs.locale.language]
						}`}
					</small>
				</span>
			</div>
		);
	};

	const RecipeContent = () => {
		return (
			<div
				ref={contentRef}
				className={
					"flex-1 bg-white rounded-xl md:rounded-none -mt-4 md:mt-0 z-50 overflow-hidden"
				}
			>
				<div className={"p-6 md:p-10 p-12 md:pb-20 text-pretty"}>
					<RecipeTime />
					<h2 className={"mb-2 md:mb-3"}>
						<strong>{recipe.name}</strong>
					</h2>
					<h5>{formatMarkdown(recipe.description)}</h5>
					<IngredientsAndInstructions
						ingredients={recipe.ingredients}
						instructions={recipe.preparation_instructions}
						lang={pageArgs.locale.language}
					/>
				</div>
			</div>
		);
	};

	if (recipe) {
		return (
			<>
				<div
					ref={containerRef}
					className="flex flex-col md:flex-row md:h-[815px] text-grey5 max-w-[2000px] h-auto m-auto"
				>
					<RecipeImage />
					<RecipeContent />
				</div>
				<StickyBanner
					lang={pageArgs.locale.language}
					mobileView={mobileView}
				/>
				{!mobileView && <Cta lang={pageArgs.locale.language} />}
			</>
		);
	}
};

export default RenderRecipe;
