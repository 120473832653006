import { stickyBanner } from "./text";
import React from "react";

export const StickyBanner = ({ lang, mobileView }) => {
	return (
		<div className="fixed md:relative md:py-4 w-full z-50 bottom-0 md:bottom-auto md:mt-[-50px] md:mb-[-50px]">
			<div className="flex items-center justify-between max-w-3xl m-auto bg-white px-4 py-2 md:px-6 md:rounded-3lg drop-shadow overflow-hidden">
				<div className={"flex items-center"}>
					<div className="mr-4">
						<img
							src="https://d2jxuf8ovdiw8x.cloudfront.net/uploads/sites/18/2024/09/recipe.png"
							alt="App Icon"
							className="w-12 h-12 md:w-28 md:h-28 md:-mb-10 md:-mt-4"
						/>
					</div>
					<div>
						<p className="font-semibold md:text-xl">
							{stickyBanner.title[lang]}
						</p>
						<p className="text-sm md:text-base text-gray-600">
							{stickyBanner.subtitle[lang]}
						</p>
					</div>
				</div>
				<div className="">
					<a
						href={`${stickyBanner.button.link[lang]}`}
						className="px-4 md:px-6 py-2 bg-red1 text-white md:rounded rounded-full md:text-lg"
					>
						{mobileView
							? stickyBanner.button.text.mobile[lang]
							: stickyBanner.button.text.desktop[lang]}
					</a>
				</div>
			</div>
		</div>
	);
};
